<template>
    <div class="type_area" style="position:relative;" v-loading="loading">
        <div style="font-weight: bold;font-size: 18px;color: #333333;margin:16px 0 ;">请核对订单信息</div>
        <div ref="orderHeight">
            <div v-for="(item,index) in orderlist " :key="index" style="background: #FFFFFF;border-radius: 10px;padding:20px;margin-bottom:20px;">
                <div style="border-bottom: 1px solid #E4E4E4;padding-bottom: 20px;">
                    <img style="width:20px;height:18px;margin-right:10px;vertical-align: middle;" src="../../assets/images/orderInfo/store.svg" alt="">
                    <span style="font-weight: bold;font-size: 18px;color: #333333;vertical-align: middle;">{{item.cartInfo.companyName}}</span>
                </div>
                <!-- <div style="font-weight: bold;font-size: 18px;color: #333333;">西安妙济堂医药有限公司</div> -->
                <div style="margin-top:12px;display:flex;align-items: center;">
                    <div style="width: 6px;height: 16px;background: #1A9DFF;border-radius: 100px;"></div>
                    <div style="font-weight: bold;font-size: 16px;color: #333333;margin-left:6px;">收货人信息</div>
                </div>
                <div style="margin-top:12px;background: #F7F7F7;border-radius: 2px;padding:15px;">
                    <div style="display:flex;align-items: center;" v-if="item.defaultAddress">
                        <div style="font-weight: bold;font-size: 14px;color: #333333;">{{item.defaultAddress.contacter}}</div>
                        <div style="width: 0px;height: 13px;border: 1px solid #CFCFCF;margin:0 10px;"></div>
                        <div style="font-weight: bold;font-size: 14px;color: #333333;">{{item.defaultAddress.mobile}}</div>
                    </div>
                    <div v-else class="nodata_box" style="padding:0;">
                        <img style="height:100px;width:100px;" src="../../assets/images/index/nodata.png" alt="">
                        <span>暂无数据哦~</span>
                    </div>
                    <div style="display:flex;align-items: center;margin-top:10px;justify-content: space-between;height:30px;">
                        <div style="display:flex;align-items: center;" v-if="item.defaultAddress">
                            <div style="font-size: 12px;color: #666666;"> {{item.defaultAddress.province}}{{item.defaultAddress.city}}{{item.defaultAddress.area}}{{item.defaultAddress.address}}</div>
                            <div v-if="item.defaultAddress.isDefault" style="width: 65px;height: 22px;background: #1A9DFF;border-radius: 12px;color:#fff;font-size: 12px;text-align: center;line-height: 22px;margin-left:10px;">默认地址</div>
                        </div>
                        <div style="display:flex;align-items: center;" v-else>

                        </div>
                        <div style="font-size: 12px;color: #999999;cursor: pointer;" @click="changeAddress(item,index)">更换收货地址 <i class="el-icon-arrow-right" style="color:#666"></i></div>
                    </div>
                </div>

                <addressDialog v-if="addressVisible" :addressVisible="addressVisible" @addressFinish="addressFinish" :defaultAddressId="defaultAddressId" :addressList="addressList" @addressRetry="addressRetry" @closed="closed"></addressDialog>
                <div style="display:flex;align-items: center;margin:12px 0;justify-content: space-between;">
                    <div style="display:flex;align-items: center;">
                        <div style="width: 6px;height: 16px;background: #1A9DFF;border-radius: 100px;"></div>
                        <div style="font-weight: bold;font-size: 16px;color: #333333;margin-left:6px;">商品清单</div>
                    </div>
                    <div class="add_goods" style="color:#1A9DFF;cursor: pointer;" v-if="pageType == 'audit'" @click="addGoodsClick">+新增商品</div>
                </div>
                <div style="height: 40px;background: #FFFFFF;display:flex;align-items:center;font-size: 14px;color: #333333;justify-content: space-between;border: 1px solid #EEEEEE;border-bottom:none;">
                    <div style="display:flex;align-items:center;margin-right:100px;width:400px;margin-left:10px;">
                        <div style="">商品信息</div>
                    </div>
                    <div style="display:flex;align-items:center;flex:1;justify-content: space-around;text-align:center;">
                        <div style="width:100px;">单价</div>
                        <div style="width:200px;">数量</div>
                        <div style="width:100px;">金额</div>
                        <div style="width:100px;" v-if="pageType == 'audit'">操作</div>
                    </div>
                </div>
                <div v-if="item.cartInfo.cartGoodsInfo.length" style="border: 1px solid #EEEEEE;padding:0 0 10px;" >
                    <div v-for="(items,indexs) in arrow?item.cartInfo.cartGoodsInfo:item.cartInfo.cartGoodsInfo.slice(0,6)" :key="items.cart.id" :class="{'lastone':arrow?indexs ==5 :indexs ==item.cartInfo.cartGoodsInfo.length-1 }">
                        <div style="display:flex;align-items:center;">
                            <div style="display:flex;align-items:center;margin-right:110px;width:400px;">
                                <!-- <div style="margin:0 10px;">
                            <el-checkbox v-model="item.checked"></el-checkbox>
                        </div> -->
                                <div style="position:relative;margin:10px 20px 0;">
                                    <img style="width:130px;height:130px;" :src="items.goodsSingleInfo.thumbUrl" alt="">
                                    <!-- <img style="width:130px;height:130px;position:absolute;top:0;left:0;" src="../../assets/images/shopCar/soonGet.png" alt=""> -->
                                </div>
                                <div style="font-size: 14px;color: #999999;">
                                    <div style="display:flex;align-items:center;margin-bottom:12px;">
                                        <div class="tejia" v-if="items.cart.actType==2">特价</div>
                                        <div style="font-weight: bold;font-size: 16px;color: #333333;">{{items.goodsSingleInfo.productName}}</div>
                                    </div>
                                    <div style="width:250px;"><span style="display:inline-block;width:30px;margin-right: 10px;">厂商</span> <span style="color: #333333;">{{items.goodsSingleInfo.produceUnit}}</span></div>
                                    <div v-if="items.goodsSingleInfo.isExpired"><span style="display:inline-block;width:30px;margin-right: 10px;">效期</span> <span style="color:#FF6A05;">优于{{items.goodsSingleInfo.lastDate}}(近效期)</span></div>
                                    <div v-else><span style="display:inline-block;width:30px;margin-right: 10px;">效期</span> <span style="color: #333333;">优于{{items.goodsSingleInfo.lastDate}}</span></div>
                                    <div style="overflow:hidden;white-space:nowrap;width:220px;text-overflow:ellipsis;"><span style="display:inline-block;width:30px;margin-right: 10px;">规格</span> <el-tooltip class="item" effect="dark" :disabled="items.goodsSingleInfo.norms.length<23" :content="items.goodsSingleInfo.norms" placement="top-start">
                                            <span style="color: #333333;">{{items.goodsSingleInfo.norms}}</span>
                                        </el-tooltip></div>
                                    <div><span style="display:inline-block;width:30px;margin-right: 10px;">库存</span> <span style="color: #333333;">{{items.goodsSingleInfo.stock}}</span></div>
                                </div>
                            </div>
                            <div style="display:flex;align-items:center;flex:1;justify-content: space-around;text-align: center;">
                                <div style="font-size: 18px;color: #F92B2B;width:100px;">
                                    <div>￥{{(items.goodsSingleInfo.jiage).toFixed(2)}}</div>
                                    <div style="color:#333;font-size: 14px;text-decoration: line-through;" v-if="items.goodsSingleInfo.jiage!=items.goodsSingleInfo.price">￥{{(items.goodsSingleInfo.price).toFixed(2)}}</div>
                                </div>
                                <div style="text-align: center;width:200px;">

                                    <el-input-number v-if="pageType == 'audit'" style="width:140px;" @change="quantityChange(items.cart)" v-model="items.cart.quantity" :min="items.goodsSingleInfo | minStep" :step="items.goodsSingleInfo | minStep" :precision="0"></el-input-number>
                                    <div v-else style="font-size: 18px;color: #333333;font-weight: 600;">{{items.cart.quantity}}</div>
                                    <div v-if="items.goodsSingleInfo.bargain||(items.goodsSingleInfo.acts.length&&items.goodsSingleInfo.acts[0].actType==2)||(items.goodsSingleInfo.acts.length&&items.goodsSingleInfo.acts[0].actType==4)" style="font-size: 12px;color: #1A9DFF;">{{items.goodsSingleInfo.bargain||items.goodsSingleInfo.acts[0].description}}</div>
                                </div>
                                <div style="font-weight: bold;font-size: 18px;color: #F92B2B;width:100px;">￥{{(items.goodsSingleInfo.jiage*items.cart.quantity).toFixed(2)}}</div>
                                <div v-if="pageType == 'audit'" class="delete" style="cursor: pointer;width:100px;font-size: 14px;" @click="deleteClick(items.cart)">删除</div>
                            </div>
                        </div>
                        <div class="donate" v-for="(ele,ind) in items.giftsSendInfo" :key="ind">
                            <div style="display:flex;align-items:center;margin-right:100px;margin-left:10px;"><img src="../../assets/images/shopCar/donate.svg" alt="">
                                <div style="margin-left:10px;width:100px;">【赠品】</div>
                            </div>
                            <div style="width:300px;">{{ele.goodsSingleInfo.productName}}</div>
                            <div style="width:300px;">优于{{ele.goodsSingleInfo.lastDate}}</div>
                            <div style="width:200px;">{{ele.goodsSingleInfo.norms}}</div>
                            <div style="width:300px;">{{ele.cart.quantity}}{{ele.goodsSingleInfo.unit}}</div>
                            <div style="width:18px;height:18px;"><img src="../../assets/images/orderInfo/yellowright.svg" alt=""></div>
                        </div>
                    </div>
                    <div class="donate" style="margin-top:10px;" v-for="(el,indexs) in item.cartInfo.promotionGiftsSendInfo" :key="indexs">
                        <div style="display:flex;align-items:center;margin-right:100px;margin-left:10px;"><img src="../../assets/images/shopCar/donate.svg" alt="">
                            <div style="margin-left:10px;width:100px;">【赠品】</div>
                        </div>
                        <div style="width:300px;">{{el.goodsSingleInfo.productName}}</div>
                        <div style="width:300px;">优于{{el.goodsSingleInfo.lastDate}}</div>
                        <div style="width:200px;">{{el.goodsSingleInfo.norms}}</div>
                        <div style="width:300px;">{{el.cart.quantity}}{{el.goodsSingleInfo.unit}}</div>
                        <div style="width:18px;height:18px;"><img src="..//..//assets/images/orderInfo/yellowright.svg" alt=""></div>
                    </div>

                </div>
                <div v-else>
                    <div class="nodata_box">
                        <img src="@/assets/images/index/nodata.png" alt="">
                        <span>暂无数据哦~</span>
                    </div>
                </div>
                <div ref="arrowHeight" @click="openMore">
                    <div v-if="item.cartInfo.cartGoodsInfo.length>6&&arrow" style="height: 36px;background: #F5F5F5;margin-top:10px;text-align:center;line-height: 36px;font-size: 12px;color: #333333;cursor: pointer;">收起{{item.cartInfo.cartGoodsInfo.length-6}}件商品
                        <i class="el-icon-arrow-up"></i>
                    </div>
                    <div v-if="item.cartInfo.cartGoodsInfo.length>6&&!arrow" style="height: 36px;background: #F5F5F5;margin-top:10px;text-align:center;line-height: 36px;font-size: 12px;color: #333333;cursor: pointer;">展开{{item.cartInfo.cartGoodsInfo.length-6}}件商品
                        <i class="el-icon-arrow-down"></i>
                    </div>
                </div>
                <div ref="allHeight">
                    <div style="margin-top:12px;display:flex;align-items: center;padding-bottom: 10px;">
                        <div style="width: 6px;height: 16px;background: #1A9DFF;border-radius: 100px;"></div>
                        <div style="font-weight: bold;font-size: 16px;color: #333333;margin-left:6px;">发票信息</div>
                    </div>
                    <div style="display:flex;align-items: center;">
                        <div class="invoice" v-for="(el,indexs) in item.invoiceList" :key="indexs" :class="{'select-invoice':item.invoiceId==el.id}" @click="selectInvoice(item,el,index)">{{el.title}}</div>
                    </div>
                    <div style="margin-top:12px;display:flex;align-items: center;">
                        <div style="width: 6px;height: 16px;background: #1A9DFF;border-radius: 100px;"></div>
                        <div style="font-weight: bold;font-size: 16px;color: #333333;margin-left:6px;">优惠券</div>
                    </div>
                    <div style="background: #FFFFFF;border: 1px solid #EEEEEE;padding:10px 15px;margin-top:12px;">
                        <div v-if="item.orderCouponInfos.orderCouponInfo.length" style="display:flex;flex-wrap:wrap">
                            <div v-for="(ele,ind) in item.orderCouponInfos.orderCouponInfo" :key="ind" style="margin-left:10px;margin-bottom:10px;">
                                <div class="coupons" :class="{'no-coupons':ele.canUse===0}" @click="useCoupon(ele.isUse,item,ele.id)">
                                    <div style="width:66px;">
                                        <div style="font-size: 22px;" v-if="ele.couponType===1"><span style="font-size: 9px;">￥</span>{{ele.reduceMoney}}</div>
                                        <div style="font-size: 22px;" v-if="ele.couponType===2">{{ele.discount}}折</div>
                                        <div style="font-size: 10px;">满{{ele.fullAmount}}可用</div>
                                    </div>
                                    <div style="position:absolute;top:22px;right:32px;" v-if="ele.canUse!==0">
                                        <div v-if="ele.isUse" style="width: 14px;height: 14px;border: 1px solid #EE9CA8;border-radius:50%;position:relative;background:#EE9CA8;">
                                            <img style="    position: absolute;top: 4px;left: 3px;" src="../../assets/images/orderInfo/mark.svg" alt="">
                                        </div>
                                        <div v-else style="width: 14px;height: 14px;border: 1px solid #EE9CA8;border-radius:50%;"></div>

                                    </div>
                                    <div>
                                        <div style="font-size: 14px;font-weight:700;">{{ele.tips}}</div>
                                        <div style="font-size: 10px;">有效期至：</div>
                                        <div style="font-size: 10px;">{{ele.startTime}}~{{ele.endTime}}</div>
                                    </div>
                                </div>
                                <div v-if="ele.description" style="display:flex;font-size: 12px;color: #BDBDBD;margin-top:3px;">
                                    <div> <i class="el-icon-warning"></i> </div>
                                    <div style="color: #333;">{{ele.description}}</div>

                                </div>
                            </div>
                        </div>
                        <div v-else style="font-size: 12px;color: #999999;text-align:center; ">
                            暂无可用优惠券~
                        </div>
                    </div>
                    <div style="margin-top:12px;display:flex;align-items: center;">
                        <div style="width: 6px;height: 16px;background: #1A9DFF;border-radius: 100px;"></div>
                        <div style="font-weight: bold;font-size: 16px;color: #333333;margin-left:6px;">订单留言</div>
                    </div>

                    <div style="margin-top:12px;" class="note">
                        <el-input type="textarea" placeholder="请输入" @change="saveNote(item,index)" maxlength="200" show-word-limit v-model="item.note"></el-input>
                    </div>
                </div>
                <div style="margin-bottom:5px;margin-top: 10px;">
                    <div style="display:flex;align-items:center;flex:1;text-align:center;">
                        <div style="font-size: 16px;margin-right:20px;"><span style="color:#F92B2B;font-weight: 600;vertical-align: middle;">{{item.cartInfo.goodsNum }}</span><span style="vertical-align: middle;">个商品，共</span><span style="color:#F92B2B;font-weight: 600;vertical-align: middle;">{{item.cartInfo.kindNum }}</span><span style="vertical-align: middle;">件</span></div>
                        <div style="font-size: 16px;margin-right:20px;">
                            <span style="vertical-align: middle;">优惠金额：</span><span style="font-weight: 600;color:#F92B2B;vertical-align: middle;">￥{{item.cartInfo.preferPrice}}</span>
                        </div>
                        <div style="font-size: 16px;"><span style="vertical-align: middle;">应付金额：</span><span style="font-size: 16px;color: #F92B2B;font-weight: 600;vertical-align: middle;">￥{{item.cartInfo.realPrice}}</span></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="orderPay" ref="orderPay" style="height: 66px;z-index:999;width:1200px;background: #FFFFFF;display:flex;align-items:center;font-size: 14px;color: #333333;justify-content: space-between;margin-top:20px;">
            <div style="display:flex;align-items:center;margin-right:100px;width:400px;margin-left:10px;font-size: 16px;color: #444141;">
                <div style="font-weight:bold;">合计：</div>
                <!-- <div><span style="font-weight:bold;color:#F92B2B">{{item.cartInfo.cartGoodsInfo.length}}</span>件商品，</div> -->
                <div>共计<span style="font-weight:bold;color:#F92B2B">{{goodsCount}}</span>个</div>
            </div>
            <div style="display:flex;align-items:center;flex:1;justify-content: space-between;text-align:center;">
                <div style="font-size: 16px;"><span style="vertical-align: middle;">商品总价：</span><span style="color:#F92B2B;font-weight: 600;vertical-align: middle;">￥{{totalPrice}}</span></div>
                <div style="width: 0px;height: 13px;border: 1px solid #CFCFCF;margin:0 10px;"></div>
                <div style="font-size: 16px;">
                    <el-tooltip effect="dark" placement="top">
                        <div slot="content">
                            <span v-for="(items,ind) in discountDetail" :key="ind" style="display:block;">
                                {{items.description}}
                            </span>
                        </div>
                        <img style="vertical-align: middle;margin-right:5px; " :class="{'no-pointer':discountDetail.length==0}" src="../../assets/images/shopCar/explain.svg" alt="">
                    </el-tooltip>
                    <span style="vertical-align: middle;">优惠金额：</span><span style="font-weight: 600;color:#F92B2B;vertical-align: middle;">￥{{preferPrice}}</span>
                </div>
                <div>
                    <div style="font-size: 16px;"><span style="vertical-align: middle;">应付金额：</span><span style="font-size: 22px;color: #F92B2B;font-weight: 700;vertical-align: middle;">￥{{realPrice}}</span></div>
                    <!-- <div style="font-size: 12px;color: #999999;" v-if="realPrice>800">（包含运费：30元，满800免运费）</div> -->
                </div>
                <div @click="subPay" style="width: 155px;height: 66px;background: #1A9DFF;font-weight: bold;font-size: 22px;color: #FFFFFF;text-align: center;line-height: 66px;cursor: pointer;">提交订单</div>
            </div>
        </div>
        <el-dialog :visible="noticeVisible" center="center" title="提示" width="22%" class="else-dialog" @close="noticeclosed">

            <div v-for="(item,index) in multipleCartNotice" :key="index" style="font-size:16px;margin-bottom:10px;">
                <span>{{index+1}}.</span><span style="color:red;font-weight:600">{{item.goodsInfo[0]}}</span><span>{{item.message}}</span>
            </div>

        </el-dialog>
    </div>
</template>

<script>
import addressDialog from '@/views/pay/component/payDialog'
export default {
    components: {
        addressDialog
    },
    data() {
        return {
            pageType: '',
            noticeVisible: false,
            addressVisible: false,
            notice: [],
            multipleCartNotice: [],
            orderlist: [],
            list: [

            ],
            loading: false,
            invoiceType: 0,
            selectCoupon: false,
            defaultAddress: '',
            addressList: [],
            invoiceList: [],
            defaultAddressId: 0,
            preferPrice: '',
            realPrice: '',
            totalPrice: '',
            note: '',
            promotionGiftsSendInfo: [],
            orderCouponInfos: [],
            couponId: '',
            discountDetail: [],
            offsetTop: 0,
            allHeight: 0,
            arrow: false,
            originHeight: 0
        }
    },
    async created() {

        scrollTo(0, 0)

        // this.getInvoice()
        await this.getOrderInfo([])

        this.update()
    },
    mounted() {

        this.pageType = this.$route.query.type
        this.$store.commit('setNote', '')
        this.$store.commit('setInvoce', 0)
        window.addEventListener('scroll', this.removeFixed, true)
    },
    destroyed() {
        //销毁监听
        window.removeEventListener("scroll", this.removeFixed, true)
    },
    filters: {
        minStep(item) {
            let num = 1
            item.Pack.forEach(item1 => {
                if (item1.isPack == 'on') {
                    num = item1.num
                }
            })
            return Number(num)
        }
    },
    watch: {
        // note: {
        //     handler(val) {
        //         this.$store.commit('setNote', val)
        //         //  console.log(val,this.$store.state.note);
        //     },

        // },
        // invoiceType: {
        //     handler(val) {
        //         this.$store.commit('setInvoce', val)
        //         //   console.log(val,this.$store.state.invoiceType);
        //     },
        // },
    },
    computed: {
        goodsCount() {
            return this.orderlist.reduce((pre, item) => {
                return pre + item.cartInfo.totalQuantity
            }, 0)
        }
    },
    methods: {
        removeFixed() {
            if (document.querySelector('.orderPay')) {
                this.$nextTick(() => {
                    if (document.documentElement.scrollTop + window.innerHeight >= this.offsetTop) {
                        // + this.allHeight
                        console.log(this.offsetTop, 'offsetTop', this.allHeight, 'allHeight');
                        // console.log(document.documentElement.scrollTop, 'document.documentElement.scrollTop', window.innerHeight, 'window.innerHeight');
                        document.querySelector('.orderPay').style.position = 'relative'
                        document.querySelector('.orderPay').style.boxShadow = '0px 0px 0px 0px rgba(0, 0, 0, 0)'
                    } else {

                        document.querySelector('.orderPay').style.position = 'fixed'
                        document.querySelector('.orderPay').style.bottom = '0'
                        document.querySelector('.orderPay').style.boxShadow = '0px -10px 10px 0px rgba(0, 0, 0, 0.1)'
                    }
                })
            }
        },
        update() {
            this.$nextTick(() => {

                this.offsetTop = this.$refs.orderPay.offsetTop
                console.log(this.$refs.orderPay.offsetTop, 'this.$refs.orderPay.offsetTop');
                this.originHeight = this.$refs.orderPay.offsetTop
                this.allHeight = this.$refs.allHeight.offsetHeight
                if (document.documentElement.scrollTop + window.innerHeight >= this.offsetTop) {
                    document.querySelector('.orderPay').style.position = 'relative'
                    document.querySelector('.orderPay').style.boxShadow = '0px 0px 0px 0px rgba(0, 0, 0, 0)'
                } else {
                    document.querySelector('.orderPay').style.position = 'fixed'
                    document.querySelector('.orderPay').style.bottom = '0'
                    document.querySelector('.orderPay').style.boxShadow = '0px -10px 10px 0px rgba(0, 0, 0, 0.1)'
                }

            })
        },
        changeAddress(item, index) {
            this.addressIndex = index

            this.addressList = item.addressInfo
            this.defaultAddressId = item.defaultAddressId
            this.addressVisible = true
            console.log(this.defaultAddressId, '12');
        },
        saveNote(item, index) {
            // sessionStorage.setItem('note'+index,JSON.stringify(item.note))
            this.$store.commit('setNote', { note: item.note, index: index })
            console.log(this.$store.state, 'asxxxxxx');

        },
        openMore() {
            this.arrow = !this.arrow
            this.$nextTick(() => {
                if (this.arrow) {
                    this.offsetTop = this.$refs.orderPay.offsetTop
                } else {
                    this.offsetTop = this.originHeight
                }
            })

            //  this.allHeight = 0

            // this.update()

        },
        selectInvoice(item, el, ind) {

            item.invoiceId = el.id
            // sessionStorage.setItem('invoice'+ind,JSON.stringify(el.id))
            this.$store.commit('setInvoce', { invoiceId: item.invoiceId, index: ind })
            console.log(el, this.orderlist);
        },
        // 审核订单新增商品
        addGoodsClick() {
            this.$router.push('/onlineOrder?type=audit&prepareOrderNumber=' + this.$route.query.prepareOrderNumber)
        },
        // 审核订单修改数量
        quantityChange(item) {
            let params = {
                "prepareOrderNumber": this.$route.query.prepareOrderNumber,
                "prepareOrderGoodsId": item.id,
                "num": item.quantity
            }
            this.$api.pcEditPrepareOrder(params).then((res) => {
                if (res.data.code === 200) {
                    this.getOrderInfo([])
                }
            });
        },
        // 审核订单删除
        deleteClick(item) {
            let params = {
                "prepareOrderNumber": this.$route.query.prepareOrderNumber,
                "prepareOrderGoodsId": item.id
            }
            this.$api.pcDeletePrepareOrder(params).then((res) => {
                if (res.data.code === 200) {
                    this.$toast('删除成功!');
                    this.getOrderInfo([])
                }
            });
        },
        addressFinish(val) {
            console.log(val);
            const newAddress = this.addressList.filter(item => item.id === val)[0]
            this.orderlist[this.addressIndex].defaultAddress = newAddress
            this.orderlist[this.addressIndex].defaultAddressId = val
            this.orderlist[this.addressIndex].addressId = val

            this.addressVisible = false
        },
        //重新支付
        addressRetry() {
            this.addressVisible = false
        },
        //关闭弹窗
        closed() {
            this.addressVisible = false
        },
        noticeclosed() {
            this.noticeVisible = false
        },
        async getOrderInfo(multipSubmitCarts) {
            // this.discountDetail = []
            // this.couponId = multipSubmitCarts
            console.log(this.$store.state, 'this.$store.state');

            this.loading = true
            const res = await this.$orderApis.multipleSubOrder({
                multipSubmitCarts,
                prepareOrderNumber: this.$route.query.prepareOrderNumber
            })
            if (res.data.code === 200) {

                this.preferPrice = res.data.data.preferPrice
                this.realPrice = res.data.data.realPrice
                this.totalPrice = res.data.data.totalPrice

                const res2 = await this.$orderApis.multipleAddress({
                    prepareOrderNumber: this.$route.query.prepareOrderNumber,
                })

                if (res2.data.code === 200) {
                    // console.log(res2.data.data, 'res2.data.data');
                    if (res2.data.data.length) {
                        res.data.data.multipSubmitCartInfo.forEach(item => {
                            res2.data.data.forEach(item2 => {
                                if (item.cartInfo.memberId == item2.memberId) {
                                    item.addressInfo = item2.addressInfo
                                }
                            })
                        })
                    }

                    res.data.data.multipSubmitCartInfo.forEach((item, index) => {
                        if (this.$store.state.note.length) {
                            item.note = this.$store.state.note[index] ? this.$store.state.note[index].note : ''
                        }
                        if (this.$store.state.invoiceType.length) {
                            item.invoiceId = this.$store.state.invoiceType[index] ? this.$store.state.invoiceType[index].invoiceId : ''
                        }

                        if (item.addressInfo && item.addressInfo.length) {
                            item.addressInfo = item.addressInfo.filter(item2 => item2.statusTitle == '审核通过')
                            if (item.addressInfo.some(el => el.isDefault === 1)) {
                                item.defaultAddress = item.addressInfo.filter(el => el.isDefault === 1)[0]
                                item.defaultAddressId = item.defaultAddress.id
                            } else {
                                item.defaultAddress = item.addressInfo[0]
                                item.defaultAddressId = item.defaultAddress.id
                            }
                        }
                    })


                    // this.addressList = res.data.data.filter(item => item.statusTitle == '审核通过')
                    // if (res.data.data.length) {
                    //     if (this.addressList.some(item => item.isDefault === 1)) {
                    //         this.defaultAddress = this.addressList.filter(item => item.isDefault === 1)[0]
                    //         this.defaultAddressId = this.defaultAddress.id
                    //     } else {
                    //         this.defaultAddress = this.addressList[0]
                    //         this.defaultAddressId = this.defaultAddress.id
                    //     }
                    // }

                    // console.log(this.defaultAddress.id, 'qwe');
                }
                const res1 = await this.$orderApis.getInvoice()
                if (res1.data.code === 200) {
                    res.data.data.multipSubmitCartInfo.forEach(item => {
                        item.invoiceList = res1.data.data
                    })
                }
                this.orderlist = res.data.data.multipSubmitCartInfo
                this.discountDetail = res.data.data.actNotice ? res.data.data.actNotice : []
                this.loading = false
                console.log(this.orderlist, 'this.orderlist');
                // this.promotionGiftsSendInfo = res.data.data.cartInfo.promotionGiftsSendInfo
                // this.orderCouponInfos = res.data.data.orderCouponInfos.orderCouponInfo
                // if (res.data.data.addressId) {
                //     this.defaultAddressId = res.data.data.addressId
                //     this.defaultAddress = this.addressList.filter(item => item.id === res.data.data.addressId)[0]
                // }
                // res.data.data.cartInfo.cartActInfo.forEach((item) => {
                //     if (item.actType != 4) {
                //         item.goodsInfo.forEach(el => {
                //             this.discountDetail.push(el)
                //         })
                //     }
                // })
                // if (res.data.data.note) {
                //     this.note = res.data.data.note
                // } else {
                //     this.note = this.$store.state.note
                // }

                // if (res.data.data.invoiceId) {
                //     // console.log(res.data.data.invoiceId,'invoiceId');
                //     this.invoiceType = res.data.data.invoiceId
                // } else {
                //     this.invoiceType = this.$store.state.invoiceType
                // }
                this.multipleCartNotice = res.data.data.multipleCartNotice
                if (this.multipleCartNotice.length > 0) {
                    this.noticeVisible = true
                }
            }
        },
        //获取地址
        // async getAddress() {
        //     const res = await this.$orderApis.multipleAddress({
        //         prepareOrderNumber: this.$route.query.prepareOrderNumber,
        //     })

        //     if (res.data.code === 200) {
        //         console.log(res.data.data, 'res.data.data');
        //         this.orderlist.forEach(item => {
        //             res.data.data.forEach(item2 => {
        //                 if (item.cartInfo.memberId == item2.memberId) {
        //                     item.addressInfo = item2.addressInfo
        //                 }
        //             })
        //         })

        //         this.orderlist.forEach(item => {
        //             item.addressInfo = item.addressInfo.filter(item2 => item2.statusTitle == '审核通过')
        //             if (item.addressInfo.length) {
        //                 if (item.addressInfo.some(el => el.isDefault === 1)) {
        //                     item.defaultAddress = item.addressInfo.filter(el => el.isDefault === 1)[0]
        //                     item.defaultAddressId = item.defaultAddress.id
        //                 } else {
        //                     item.defaultAddress = item.addressInfo[0]
        //                     item.defaultAddressId = item.defaultAddress.id
        //                 }
        //             }
        //         })

        //         console.log(this.orderlist, 'this.orderlist');
        //         // this.addressList = res.data.data.filter(item => item.statusTitle == '审核通过')
        //         // if (res.data.data.length) {
        //         //     if (this.addressList.some(item => item.isDefault === 1)) {
        //         //         this.defaultAddress = this.addressList.filter(item => item.isDefault === 1)[0]
        //         //         this.defaultAddressId = this.defaultAddress.id
        //         //     } else {
        //         //         this.defaultAddress = this.addressList[0]
        //         //         this.defaultAddressId = this.defaultAddress.id
        //         //     }
        //         // }

        //         // console.log(this.defaultAddress.id, 'qwe');
        //     }
        // },
        //获取发票
        // async getInvoice() {
        //     const res = await this.$orderApis.getInvoice()
        //     if (res.data.code === 200) {
        //         res.data.data.forEach(item => {
        //             item.invoiceType = ''
        //         })
        //         this.orderlist.forEach(item => {
        //             item.invoiceList = res.data.data
        //         })
        //     }
        // },
        //使用优惠卷
        useCoupon(use, item, id) {
            const filterArr = this.orderlist.filter(el => el.couponId != 0 && el.couponId != id)
            const multipSubmitCarts = filterArr.map(ele => {
                return {
                    memberId: ele.cartInfo.memberId,
                    getCouponId: ele.couponId
                }
            })
            if (!use) {
                multipSubmitCarts.push({
                    memberId: item.cartInfo.memberId,
                    getCouponId: id
                })
            }
            this.getOrderInfo(multipSubmitCarts)
        },
        //去支付
        async subPay() {
            console.log(this.orderlist, 'this.orderlist');
            if (this.orderlist.some(item => !item.defaultAddressId)) { return this.$message.warning('请选择收货地址') }
            if (this.orderlist.some(item => item.invoiceId == 0)) { return this.$message.error('请选择发票类型') }
            const subArr = this.orderlist.map(item => {
                return {
                    memberId: item.cartInfo.memberId,
                    getCouponId: item.couponId,
                    invoiceId: item.invoiceId,
                    addressId: item.defaultAddressId,
                    note: item.note
                }
            })
            this.loading = true
            const res = await this.$orderApis.multipleSub({ params: subArr, prepareOrderNumber: this.$route.query.prepareOrderNumber ? this.$route.query.prepareOrderNumber : '' })
            if (res.data.code === 200) {
                this.loading = false
                // this.$router.push(`/pay?orderNumber=${res.data.data.orderNumber}&prepareOrderNumber=${res.data.data.prepareOrderNumber}`)
                this.$router.push(`/pay?orderNumber=${res.data.data.orderNumber ? res.data.data.orderNumber : ''}&mainOrderNumber=${res.data.data.mainOrderNumber ? res.data.data.mainOrderNumber : ''}&prepareOrderNumber=${res.data.data.prepareOrderNumber ? res.data.data.prepareOrderNumber : ''}&mainPrepareOrderNumber=${res.data.data.mainPrepareOrderNumber ? res.data.data.mainPrepareOrderNumber : ''}`)
            } else {
                this.loading = false
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.no-pointer {
    pointer-events: none;
}
::v-deep .else-dialog {
    .el-dialog__header {
        .el-dialog__title {
            font-weight: bold !important;
            font-size: 25px !important;
            color: #1a1a1a !important;
        }
    }

    .el-dialog {
        border-radius: 10px 10px 10px 10px !important;
    }
}
.add_goods:hover {
    color: #0687fe !important;
}
.selected {
    background: #f5fbff;
}
.tejia {
    width: 30px;
    height: 17px;
    background: #fae5e5;
    border-radius: 2px;
    border: 1px solid #eeeeee;
    font-weight: bold;
    font-size: 10px;
    color: #f92b2b;
    line-height: 17px;
    text-align: center;
    box-sizing: border-box;
    margin-right: 10px;
    border: 1px solid #fa4343;
}
.lastone {
    border-bottom: none !important;
}
.invoice {
    border-radius: 2px;
    border: 1px solid #cecece;
    font-size: 12px;
    color: #666666;
    text-align: center;
    padding: 5px 10px;
    margin-left: 10px;
    cursor: pointer;
}
.select-invoice {
    border: 1px solid #5ebaff;
    color: #1a9dff;
}
.coupons {
    background-image: url('../../assets/images/orderInfo/special.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 215px;
    height: 70px;
    display: flex;
    flex-wrap: nowrap;
    color: #fff;
    // justify-content: space-around;
    // align-items: center;
    padding: 10px 15px;
    box-sizing: border-box;
    position: relative;
    cursor: pointer;
}
.no-coupons {
    background-image: url('../../assets/images/orderInfo/no-special.png');
    color: #ff8c8c !important;
}
.donate {
    // height: 42px;
    padding: 10px 0px;
    background: #fef9f1;
    border-radius: 4px;
    border: 1px solid #f8f2e7;
    margin: 0px 20px;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #7a5611;
}
.delete:hover {
    color: #1a9dff;
}
::v-deep.el-textarea {
    .el-textarea__inner {
        border-radius: 0px !important;
        border-color: #eeeeee !important;
    }
}
::v-deep {
    .el-input-number .el-input__inner {
        font-size: 15px;
        font-weight: 600;
    }
}
</style>